import React from "react";
import {graphql} from "gatsby";
import styled from "styled-components";
import FadeIn from "react-fade-in";
import Layout from "../components/Layout";
import CardTestimonial from "../components/CardTestimonial";

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-bottom: 132px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 85vw;
  width: 100%;

  .detailsBox {
    position: -webkit-sticky;
    position: sticky;
    bottom: 5rem;
    left: 35%;
    align-self: flex-end;
    width: 600px;
    height: auto;
    z-index: 2000;
    border-radius: 15px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3),
      -10px -10px 50px rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 0.35);
    backdrop-filter: blur(25px);

    @media only screen and (max-width: 420px) {
      bottom: 1rem;
      left: 5%;
      width: 350px;
    }

    @media only screen and (max-width: 375px) {
      bottom: 1rem;
      left: 5%;
      width: 320px;
    }
  }
`;

const CardWrap = styled.div`
  padding-right: 10px;
  padding-bottom: 30px;
  @media only screen and (max-width: 500px) {
    margin: 0 autol;
    padding-right: 0px;
  }
`;

function TestimonialPage({data}) {
  const {Testimonials} = data.directus;

  let seconds = -50;
  return (
    <Layout>
      <Wrap>
        <Container>
          {Testimonials.map((testimonial, index) => {
            seconds += 100;
            return (
              <FadeIn key={index} delay={seconds}>
                <CardWrap>
                  <CardTestimonial
                    photoDesktop={testimonial.image}
                    photoMobile={testimonial.mobileImage}
                    name={testimonial.title}
                    content={testimonial.content}
                    live={testimonial.liveURL}
                  />
                </CardWrap>
              </FadeIn>
            );
          })}
        </Container>
      </Wrap>
    </Layout>
  );
}

export const projectsPageQuery = graphql`
  query Testimonials {
    directus {
      Testimonials(sort: ["-id"]) {
        id
        title
        image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 512)
            }
          }
        }
        liveURL
        content
        slug
        seo {
          title
          description
          image {
            id
          }
        }
      }
    }
  }
`;

export default TestimonialPage;
