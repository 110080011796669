import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import stars from '../images/stars.png';
import { colors } from '../styles/colors';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  min-width: 356px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3),
    -10px -10px 50px rgba(0, 0, 0, 0.5);

  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  @media (max-width: 500px) {
    padding: 0px;
  }

  .facts {
    padding-top: 5px;
    @media (max-width: 768px) {
      margin-top: 100px;
      padding: 20px;
      margin-left: -12rem;
      margin-right: 30px;
    }

    @media (max-width: 420px) {
      margin: 0px;
      padding-left: 0px;
      box-shadow: none;
      background-color: transparent;
      backdrop-filter: none;
    }
  }

  .photoDesktop {
    cursor: pointer;
    width: 400px;
    margin: 0px auto;
    margin-top: 10px;
    border-radius: 10px;
    align-self: center;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3), 0px 0px 20px rgba(0, 0, 0, 0.5);

    @media (max-width: 1100px) {
      width: 300px;
    }

    @media (max-width: 500px) {
      width: 250px;
    }
  }

  .photoMobile {
    margin-top: -230px;
    margin-bottom: 20px;
    margin-left: 350px;
    width: 80px;
    border: 1px solid ${colors.defaultColor};
    background-color: rgb(59, 23, 61);
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3),
      -10px -10px 30px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    @media (max-width: 1100px) {
      width: 50px;
      margin-left: 280px;
      margin-top: -140px;
    }

    @media (max-width: 500px) {
      width: 50px;
      margin-left: 240px;
      margin-top: -140px;
    }
  }

  .cardText {
    border-radius: 10px;
    margin: 0px;
    width: 80%;
    height: auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1100px) {
      margin: 10px 10px;
      ${'' /* width: 280px; */}
    }
  }

  .technologies {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(8, 1fr);
  }

  h3 {
    margin-left: 18px;
    margin-bottom: 4px;
    color: ${colors.defaultColor};
    line-height: 1.7rem;
    @media (max-width: 1100px) {
      margin-top: 25px;
      margin-bottom: 20px;
      ${'' /* text-align: center; */}
      ${'' /* padding: 20px; */}
    }
  }

  .code {
    border-color: transparent;
    border-bottom: 1px solid ${colors.defaultColor};
    border-radius: 0px;
  }

  .details {
    border: 1px solid ${colors.blue};
  }
`;

function CardTestimonial({
  name, photoDesktop, seconds, onClick, content, noStars = false,
}) {
  const dskImage = getImage(photoDesktop.imageFile);
  const mt = noStars ? 8 : 0;
  return (
    <Container seconds={seconds}>
      <div className="cardText">
        <h3>{name}</h3>
        {!noStars
          && <img style={{ marginBottom: 8, width: 100, height: 19 }} src={stars} alt="stars" />}
        <div className="facts">
          <GatsbyImage
            style={{
              marginTop: mt,
              width: 264,
              height: 134,
              borderRadius: '8px',
            }}
            image={dskImage}
            onClick={onClick}
          />
          <div
            style={{ width: 312, marginLeft: -18 }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </Container>
  );
}

export default CardTestimonial;
